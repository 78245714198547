import { Currency } from "@redotech/money/currencies";
import { DraftRegistration } from "@redotech/redo-model/draft-registration";
import {
  Address,
  DraftReturn,
} from "@redotech/redo-model/draft-return/draft-return";
import {
  AdvancedExchangeItem,
  DEFAULT_VARIANT_TITLE,
  PendingReturnItem,
  ReturnableItem,
  ReturnProduct,
} from "@redotech/redo-model/draft-return/draft-return-items";
import {
  getCountryName,
  getPrimaryProduct,
} from "@redotech/redo-model/draft-return/util";
import { RegistrationRewardType } from "@redotech/redo-model/extended-warranty";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { ReturnAddress, ReturnTypeEnum } from "@redotech/redo-model/return";
import { LineItem } from "@redotech/redo-model/shopify-order";
import { PurchaseChannel } from "@redotech/redo-model/warranties";
import { ProductInfo } from "@redotech/shopify-storefront/products";
import { CombinedReturnItem } from "./flow/util";
import { ProductData } from "./hooks/useFlow/use-flow";
import { ReturnAppSettings } from "./settings";
export const isInternationalReturn = (
  shipFromCountry: string | undefined,
  shipToCountry: string | undefined,
  settings: ReturnAppSettings,
): boolean => {
  let shipFromCountryName: string | undefined = shipFromCountry;
  if (shipFromCountry?.length == 2) {
    shipFromCountryName = getCountryName(shipFromCountry);
  }
  const shipToCountryName =
    shipToCountry || settings.merchantAddress?.country_name;

  return shipFromCountryName !== shipToCountryName;
};

export function ReturnAddressToAddress(address: ReturnAddress): Address {
  return {
    name: address.name ?? "",
    email: address.email ?? "",
    street1: address.address1,
    street2: address.address2,
    state: address.province,
    city: address.city,
    country: address.country ?? address.country_code ?? "",
    zip: address.zip ?? "",
    phone: address.phone,
    returnerName: address.name,
    returnerEmail: address.email,
  };
}

export function removeGidFromId(id: string) {
  const lastSlash = id.lastIndexOf("/");
  if (lastSlash === -1) return id;
  return id.slice(lastSlash + 1);
}

export const CURRENCY_FORMAT = (
  currencyCode: Currency,
  signDisplay?: keyof Intl.NumberFormatOptionsSignDisplayRegistry,
) => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
    signDisplay: signDisplay,
  });
};

const continueIfOutOfStock = (
  variant: { availableForSale: boolean; quantityAvailable: number },
  settings: ReturnAppSettings | undefined,
) => {
  if (!variant || !settings) {
    return false;
  }
  return (
    settings.inventory.followShopifyInventoryPolicy && variant.availableForSale
  );
};

export const isVariantInStock = (
  variant: { availableForSale: boolean; quantityAvailable: number },
  settings: ReturnAppSettings | undefined,
) => {
  if (!variant || !settings) {
    return false;
  }
  return (
    continueIfOutOfStock(variant, settings) ||
    (variant.quantityAvailable || 0) >= settings.inventory.minimum_stock
  );
};

export const storefrontProductToProductData = (
  product: ProductInfo,
  settings: ReturnAppSettings | undefined,
): ProductData => {
  return {
    productId: product.id,
    productTitle: product.title,
    options: product.options.map((option) => ({
      name: option.name,
      values: option.values,
    })),
    variants: product.variants.nodes.map((variant) => ({
      variantId: variant.id,
      variantTitle: variant.title,
      price: {
        amount: variant.price.amount,
        currency: variant.price.currencyCode,
      },
      compareAtPrice: {
        amount: variant.price?.amount,
        currency: variant.price?.currencyCode,
      },
      image: variant.image ?? product.featuredImage ?? { url: "", altText: "" },
      options: variant.selectedOptions,
      onlineInventoryAvailable: variant.quantityAvailable ?? 0,
      isInStock: isVariantInStock(
        {
          availableForSale: variant.availableForSale,
          quantityAvailable: variant.quantityAvailable ?? 0,
        },
        settings,
      ),
    })),
  };
};
export enum ItemDetailSize {
  SMALL = "small",
  MEDIUM = "medium",
}

export interface Variant {
  name: string;
  value: string;
}

type ReturnItemBadge = { text: string; color: PillTheme };

export interface ReturnItemParams {
  id: string;
  imageSrc: string;
  title: string;
  subtitle?: string;
  quantity: number;
  variants: Variant[];
  formattedPrice: string;
  isSelected: boolean;
  badgeText?: string;
  size?: ItemDetailSize;
  currency: Currency;
  price: string;
  itemValue?: string | null;
  returnTypeBadges?: ReturnItemBadge[];
}

/**
 * Break out any items being returned by the quantity & pending return intent, such that
 * we have a separate item for each grouping of quantities being returned of each pending item,
 * and items with the rest (the leftover quantities not being returned)
 *
 * N.B. This isn't the thing that's grouping return items originally,
 * see `consolidateReturnableItems()` for more.
 **/
export function getCombinedReturnItemsGrouped(
  draftReturn?: DraftReturn,
): CombinedReturnItem[] {
  if (!draftReturn) {
    return [];
  }
  const combinedReturnItems: CombinedReturnItem[] = [];
  for (const returnableItem of draftReturn.returnableItems) {
    const associatedPendingItems = draftReturn.pendingReturnItems.filter(
      (pendingItem) => pendingItem.returnableItemId === returnableItem.id,
    );

    if (associatedPendingItems.length === 0) {
      const primaryProduct = getPrimaryProduct(returnableItem);
      combinedReturnItems.push({
        ...returnableItem,
        availableQuantity: primaryProduct?.quantity ?? 1,
      });
    } else {
      const returnQuantity = associatedPendingItems.reduce(
        (acc, pendingItem) => acc + (pendingItem.quantity ?? 1),
        0,
      );

      const availableQuantity =
        (getPrimaryProduct(returnableItem)?.quantity ?? 1) - returnQuantity;
      for (const pendingItem of associatedPendingItems) {
        combinedReturnItems.push({
          ...returnableItem,
          pendingItem,
          availableQuantity,
        });
      }

      /** The remaining quantity is added as a separate item without any pending items*/
      if (availableQuantity > 0) {
        combinedReturnItems.push({ ...returnableItem, availableQuantity });
      }
    }
  }

  return combinedReturnItems.filter((item) => item.selected);
}

function filterOutDefaultTitleVariants<T>(
  variants: T[] | undefined,
  entryFn: (v: T) => string,
): T[] {
  return (
    variants?.filter(
      (v) =>
        ![DEFAULT_VARIANT_TITLE, "Title", "none"].includes(entryFn(v) ?? ""),
    ) ?? []
  );
}

export function subtitleWithoutDefault(v?: string): string | undefined {
  return v ? filterOutDefaultTitleVariants([v], (v) => v).pop() : undefined;
}

export function variantsWithoutDefault(v?: Variant[]): Variant[] {
  return filterOutDefaultTitleVariants(v, (v) => v.name).filter(
    (v) =>
      !(v.name === "Quantity" && (v.value === "1" || v.value === undefined)),
  );
}

/**
 * Convert return items into arguments usable by <ItemDetail /> and other UI components.
 *
 * Is smart enough to figure out whether to use the primary product, some other product, or a variant exchange if applicable.
 *
 * @param product - if showing the non-primary product. Otherwise use the primary one.
 * @param onlyShowOriginal - if true, display the original item, not the variant exchange item (e.g. for the item detail page).
 * @param returnType - the return type of the draft return
 * Will still use quantity information from the pending return intent if applicable.
 */
export function returnItemToParams({
  item,
  returnType,
  product,
  showBadge,
  onlyShowOriginal,
}: {
  item: ReturnableItem | CombinedReturnItem;
  returnType?: ReturnTypeEnum;
  product?: ReturnProduct;
  showBadge?: boolean;
  onlyShowOriginal?: boolean;
}): ReturnItemParams | undefined {
  const productToUse =
    product ??
    item.products.find((product) => product.id === item.primaryProductId);
  if (!productToUse) {
    return undefined;
  }

  /** Pull some information from the pending return intent if applicable */
  const pendingItem =
    "pendingItem" in item && item.pendingItem ? item.pendingItem : undefined;

  /** (For items on item detail page not associated with a pending return intent) */
  const quantityLeftOver =
    "availableQuantity" in item && item.availableQuantity
      ? item.availableQuantity
      : undefined;

  /** Use the pending quantity intent, or the quantity left over (for items on item detail page not associated with a pending return intent),
   * or the product quantity */
  const quantity =
    pendingItem?.quantity ?? quantityLeftOver ?? productToUse.quantity ?? 1;

  /** If there's a variant exchange, display that and not the product selected options */
  const displayablePendingItem =
    (!onlyShowOriginal && pendingItem?.variantExchangeItem) || undefined;

  const selectedOptions = !displayablePendingItem
    ? variantsWithoutDefault(
        productToUse.variantData.variants.find(
          (variant) => variant.id == productToUse.variantId,
        )?.selectedOptions,
      )
    : [];

  const { primaryBadge, returnTypeBadges } = getReturnItemBadges({
    returnType,
    pendingItem,
    onlyShowOriginal,
    showBadge,
  });

  const currency = Currency[productToUse.currency as keyof typeof Currency];

  return {
    id: item.id,
    imageSrc: displayablePendingItem?.image ?? productToUse.image,
    title: displayablePendingItem?.productTitle ?? productToUse.title,
    subtitle: subtitleWithoutDefault(displayablePendingItem?.title),
    quantity,
    variants: selectedOptions ?? [],
    formattedPrice: CURRENCY_FORMAT(currency).format(+productToUse.price),
    isSelected: item.selected ?? false,
    badgeText: primaryBadge,
    currency,
    price: productToUse.price,
    itemValue: productToUse.returnValue,
    returnTypeBadges,
  };
}

enum ReturnCompensationBadgeType {
  VARIANT_EXCHANGE = "variant_exchange",
  SIMILAR_ITEM = "similar_item",
  REFUND = "refund",
  REPAIR = "repair",
}

const returnCompensationBadgeTypeToText: Record<
  ReturnCompensationBadgeType,
  string
> = {
  [ReturnCompensationBadgeType.VARIANT_EXCHANGE]: "Variant exchange",
  [ReturnCompensationBadgeType.SIMILAR_ITEM]: "Similar item",
  [ReturnCompensationBadgeType.REFUND]: "Refund",
  [ReturnCompensationBadgeType.REPAIR]: "Ready for repair",
};

const returnCompensationBadgeTypeToColor: Record<
  ReturnCompensationBadgeType,
  PillTheme
> = {
  [ReturnCompensationBadgeType.VARIANT_EXCHANGE]: PillTheme.NEUTRAL_BLUE,
  [ReturnCompensationBadgeType.SIMILAR_ITEM]: PillTheme.LIGHT_PINK,
  [ReturnCompensationBadgeType.REFUND]: PillTheme.NORMAL,
  [ReturnCompensationBadgeType.REPAIR]: PillTheme.SOLID_LIGHT_BLUE,
};

function getReturnCompensationBadge(
  type: ReturnCompensationBadgeType,
): ReturnItemBadge {
  return {
    text: returnCompensationBadgeTypeToText[type],
    color: returnCompensationBadgeTypeToColor[type],
  };
}

function determineReturnCompensationBadgeType(
  pendingItem: PendingReturnItem,
): ReturnCompensationBadgeType | undefined {
  // If the pending item has exchange information, then we should use the exchange type badges
  if (pendingItem.variantExchangeItem && !pendingItem.exchangeGroup) {
    return ReturnCompensationBadgeType.VARIANT_EXCHANGE;
  }
  if (pendingItem.exchangeGroup) {
    return ReturnCompensationBadgeType.SIMILAR_ITEM;
  }

  // If there is only one option and it's a repair, then we should use the repair badge
  const onlyOneOption = pendingItem.returnOptions.length === 1;
  const isRepair = pendingItem.returnOptions[0]?.method.type === "repair";
  if (onlyOneOption && isRepair) {
    return ReturnCompensationBadgeType.REPAIR;
  }

  // Otherwise, we should use the refund badge since they either are store credit, refund, or haven't elected a compensation method yet
  return ReturnCompensationBadgeType.REFUND;
}

const returnTypeToPrimaryBadge: Record<ReturnTypeEnum, string> = {
  [ReturnTypeEnum.RETURN]: "Ready for return",
  [ReturnTypeEnum.CLAIM]: "Claim ready",
  [ReturnTypeEnum.WARRANTY]: "Claim ready",
  [ReturnTypeEnum.MANAGED_CLAIM]: "Claim ready",
};

function getPrimaryBadge(
  showBadge?: boolean,
  returnType?: ReturnTypeEnum,
  returnCompensationBadgeType?: ReturnCompensationBadgeType,
): string | undefined {
  // If the compensation method is a repair, the compensation badge will replace the primary badge
  if (
    !showBadge ||
    returnCompensationBadgeType === ReturnCompensationBadgeType.REPAIR
  ) {
    return undefined;
  }
  return returnTypeToPrimaryBadge[returnType ?? ReturnTypeEnum.RETURN];
}

/**
 * Generate badge information for a return item
 *
 * @param pendingItem - The pending return item
 * @param onlyShowOriginal - Whether to show the original item (vs exchange)
 * @param showBadge - Whether to show the "Ready for return" badge
 * @returns Object containing primary badge text and return type badges
 */
export function getReturnItemBadges({
  returnType,
  pendingItem,
  onlyShowOriginal,
  showBadge,
}: {
  returnType?: ReturnTypeEnum;
  pendingItem?: PendingReturnItem;
  onlyShowOriginal?: boolean;
  showBadge?: boolean;
}): { primaryBadge?: string; returnTypeBadges: ReturnItemBadge[] } {
  const returnTypeBadges: ReturnItemBadge[] = [];
  const showReturnTypeBadges = onlyShowOriginal && pendingItem;
  let returnCompensationBadgeType: ReturnCompensationBadgeType | undefined;
  if (showReturnTypeBadges) {
    // Add the return reason badge if it exists on the pending item
    if (pendingItem.returnReason) {
      returnTypeBadges.push({
        text: pendingItem.returnReason,
        color: PillTheme.NORMAL,
      });
    }

    returnCompensationBadgeType =
      determineReturnCompensationBadgeType(pendingItem);
    // Add the type of compensation badge
    if (returnCompensationBadgeType) {
      returnTypeBadges.push(
        getReturnCompensationBadge(returnCompensationBadgeType),
      );
    }
  }

  const primaryBadge = getPrimaryBadge(
    showBadge,
    returnType,
    returnCompensationBadgeType,
  );

  return { primaryBadge, returnTypeBadges };
}

export function advancedExchangeItemToParams(
  item: AdvancedExchangeItem,
): ReturnItemParams | undefined {
  const imageSrc = item?.images?.[0];
  if (!imageSrc) {
    return undefined;
  }

  const currency = Currency[item.currencyCode as keyof typeof Currency];

  return {
    id: "",
    imageSrc,
    title: item.title,
    subtitle: subtitleWithoutDefault(item.variantTitle),
    quantity: item.quantity,
    variants: [],
    formattedPrice: CURRENCY_FORMAT(currency).format(Number(item.price)),
    isSelected: true,
    badgeText: undefined,
    currency,
    price: item.price,
    itemValue: item.itemValue,
  };
}

export function registrationProductToParams(
  product: ReturnProduct,
): ReturnItemParams | undefined {
  const currency = Currency[product.currency as keyof typeof Currency];
  return {
    id: product.id,
    imageSrc: product.image,
    title: product.title,
    subtitle: subtitleWithoutDefault(product.variantTitle),
    quantity: product.quantity ?? 1,
    variants: [],
    formattedPrice: CURRENCY_FORMAT(currency).format(Number(product.price)),
    isSelected: true,
    badgeText: undefined,
    currency,
    price: product.price,
    itemValue: product.returnValue,
  };
}

export function shopifyLineItemToReturnItemParams(
  lineItem: LineItem,
): ReturnItemParams | undefined {
  const currency =
    Currency[
      lineItem.price_set?.presentment_money
        ?.currency_code as keyof typeof Currency
    ];
  return {
    id: lineItem.id?.toString() ?? "",
    imageSrc: lineItem.image?.src ?? "",
    title: lineItem.title,
    subtitle: lineItem.variant_title ?? undefined,
    quantity: lineItem.quantity,
    variants: [],
    formattedPrice: CURRENCY_FORMAT(currency).format(Number(lineItem.price)),
    isSelected: true,
    badgeText: undefined,
    currency,
    price: lineItem.price,
    itemValue: lineItem.price,
  };
}

export function shouldAddEwUpsell(
  draftRegistration: DraftRegistration | undefined,
  settings: ReturnAppSettings | undefined,
): boolean {
  return !!(
    settings?.extendedWarranties?.enabled &&
    settings?.extendedWarranties?.upsellEnabled &&
    // Only retail registration are eligible for ew upsell since
    // for online we need to sell the product through shopify rather than our portal
    draftRegistration?.source?.purchaseChannel !== PurchaseChannel.ONLINE
  );
}

export function shouldAddEmailOptInUpsell(
  draftRegistration: DraftRegistration | undefined,
  settings: ReturnAppSettings | undefined,
): boolean {
  return !!(
    settings?.warranties?.registrationRewardType ===
      RegistrationRewardType.EMAIL_OPT_IN_PRODUCT_DISCOUNT &&
    draftRegistration?.userInfo?.emailOptIn === false
  );
}
