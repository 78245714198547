import { SuggestedVariantOptionsSchema } from "@redotech/redo-model/agents/suggested-variant-options";
import {
  exchangeVariantSchema,
  ReturnProductSchema,
} from "@redotech/redo-model/draft-return/draft-return-items";
import { z } from "zod";

export const getSuggestedVariantOptions = {
  input: z.object({
    product: ReturnProductSchema,
    availableVariants: z.array(exchangeVariantSchema),
    reasons: z.array(z.object({ message: z.string(), value: z.string() })),
  }),
  output: SuggestedVariantOptionsSchema,
};
