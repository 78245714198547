import { Currency } from "@redotech/money/currencies";
import { z } from "zod";
import { PricingRuleSet } from "./team";
import { InsertionPosition } from "./widget";

export enum ExtendedWarrantyCartToggleExperience {
  TOGGLE = "toggle",
  MODAL = "modal",
  REMOVE_ONLY = "remove_only",
  DISABLED = "disabled",
}

export enum ExtendedWarrantyDefaultSelection {
  CHEAPEST = "cheapest",
  NO_COVERAGE = "no_coverage",
}

export enum ExtendedWarrantyPdpExperience {
  TILES_MODAL = "tiles_modal",
  RADIO_MODAL = "radio_modal",
  UPSELL_MODAL = "upsell_modal",
}

export enum ExtendedWarrantyPdpNoCoverageType {
  DETAILED = "detailed",
  BASIC = "basic",
}

export enum ExtendedWarrantyBlurbType {
  REGULAR = "regular",
  COMPACT = "compact",
}

export enum ExtendedWarrantyTilesSize {
  REGULAR = "regular",
  COMPACT = "compact",
}

export enum ExtendedWarrantyRadioOptionType {
  REGULAR = "regular",
  EXPANDED = "expanded",
}

export enum ExtendedWarrantyPdpRenderMethod {
  APP_BLOCK = "app_block",
  SCRIPT_TAG = "script_tag",
}

export enum RegistrationRewardType {
  PRODUCT_DISCOUNT = "product_discount",
  EMAIL_DISCOUNT = "email_discount",
  REVIEW_DISCOUNT = "review_discount",
  EMAIL_OPT_IN_PRODUCT_DISCOUNT = "email_opt_in_product_discount",
  DISABLED = "disabled",
}

export interface CollectionSettings {
  collectionHandle: string;
  collectionName: string;
  validOfferings: string[];
  offerings: ExtendedWarrantyOffering[];
}

export interface ExtendedWarrantyOffering {
  timeId: string;
  timeTitle: string;
  daysExtended?: number;
  minPrice: string;
  maxPrice: string;
  percentage: string;
  internationalPricingEnabled: boolean;
  pricingRuleSet: PricingRuleSet;
}

export type ExtendedWarrantySettings = {
  enabled: boolean;
  upsellEnabled?: boolean;
  revSharePercentage: number;
  disableRevshareOnDiscountedProducts: boolean;
  variantSelector?: string;
  clickListenerSelector?: string;
  validCollections: string[];
  collectionSettings: CollectionSettings[];
  productTitle?: string;
  defaultSelection?: ExtendedWarrantyDefaultSelection;
  customPdpCss?: string;
  customToggleCss?: string;
  customModalCss?: string;
  warrantyActivationDelay?: number;
  logo?: string;
  sideImage?: string;
  modalTitle?: string;
  modalCoverageHeader?: string;
  modalDescription?: string;
  modalBulletPoints?: string[];
  modalPlanHeader?: string;
  modalSeeDetailsUrl?: string;
  pdpExperience?: ExtendedWarrantyPdpExperience;
  pdpRenderMethod?: ExtendedWarrantyPdpRenderMethod;
  pdpSelector?: string;
  pdpInsertPosition?: InsertionPosition;
  pdpTitle?: string;
  pdpLabel?: string;
  pdpNoCoveragePrice?: string;
  pdpCoverageDisabledLabel?: string;
  pdpDescription?: string;
  pdpAddToCartTitle?: string;
  pdpAddToCartDescription?: string;
  pdpAddedToCartTitle?: string;
  pdpAddedToCartDescription?: string;
  pdpCompactBlurbTitle?: string;
  pdpRadioOptionTitle?: string;
  pdpRadioOptionType?: ExtendedWarrantyRadioOptionType;
  pdpTilesSize?: ExtendedWarrantyTilesSize;
  pdpBlurbType?: ExtendedWarrantyBlurbType;
  pdpTilesBackdropEnabled?: boolean;
  pdpNoCoverageType?: ExtendedWarrantyPdpNoCoverageType;
  cartToggleModalNotAddedDescription?: string;
  cartToggleModalAddedDescription?: string;
  cartToggleAddedDescription?: string;
  cartToggleNotAddedDescription?: string;
  cartToggleExperience?: ExtendedWarrantyCartToggleExperience;
};

export type Offering = {
  title: string;
  id: string;
  collectionHandle: string;
  price: { currency: string; amount: number };
};

export type Collection = { title: string; handle: string };

// TODO: Reconcile these types
export type TitledSelection = {
  collectionHandle: string;
  timeId: string;
  title: string;
};

export const offeringSelectionSchema = z.object({
  collectionHandle: z.string(),
  timeId: z.string(),
});

export type OfferingSelection = z.infer<typeof offeringSelectionSchema>;

export type TrackedVariant = {
  variantId: string;
  productId: string;
  handle: string;
  price: number;
};

export type Selections = { [variantId: string]: OfferingSelection };

export type ExtendedWarrantyShopMetafield = {
  enabled: ExtendedWarrantySettings["enabled"];
  collectionSettings: CollectionSettings[];
  warrantyProductId?: string;
};

export type OfferingConfig = { collectionSettings: CollectionSettings[] };

export type ExtendedWarrantyShopMetafieldStyles = {
  sideImage?: string;
  logo?: string;
} & ExtendedWarrantySettings;

export const ExtendedWarrantySchema = z.object({
  variantId: z.string(),
  timeId: z.string(),
  daysExtended: z.number(),
  pricePaid: z.string(),
  collectionHandle: z.string(),
  warrantyActivationDelay: z.number(),
  quantity: z.number().nullish().optional(),
  appliedDiscount: z.string().nullish(),
});

export type ExtendedWarranty = z.infer<typeof ExtendedWarrantySchema>;

export const CART_ATTRIBUTE_KEY = "extended-warranty-selections";
export const SHOP_METAFIELD_KEY = "extended-warranty-settings";
export const SHOP_METAFIELD_NAME = "redo_extended_warranty_settings";
export const SHOP_METAFIELD_STYLES_KEY = "extended-warranty-styles";
export const SHOP_METAFIELD_STYLES_NAME = "redo_extended_warranty_styles";
export const SHOP_METAFIELD_NAMESPACE = "$app";
export const NO_COVERAGE_TIME_ID = "no_coverage";
export const DEFAULT_PRODUCT_TITLE = "Redo Extended Warranty";

export enum ExtendedWarrantyDataTarget {
  CT_NOT_ADDED_CONTAINER = "ew-ct-not-added-container",
  CT_NOT_ADDED_SHIELD = "ew-ct-not-added-shield",
  CT_NOT_ADDED_CONTENT = "ew-ct-not-added-content",
  CT_NOT_ADDED_ADD = "ew-ct-not-added-add",
  CT_ADDED_CONTAINER = "ew-ct-added-container",
  CT_ADDED_SHIELD = "ew-ct-added-shield",
  CT_ADDED_CONTENT = "ew-ct-added-content",
  CT_ADDED_REMOVE = "ew-ct-added-remove",
  CT_BUTTON = "ew-ct-button",
  CT_MODAL_CONTAINER = "ew-ct-modal-container",
  CT_MODAL_TEXT = "ew-ct-modal-text",
  CT_MODAL_BUTTON = "ew-ct-modal-button",
  CT_MODAL_CONTENT_C = "ew-ct-modal-content-container",
  PDP_CONTAINER = "ew-pdp-container",
  PDP_SHIELD = "ew-pdp-shield",
  PDP_CONTENT = "ew-pdp-content",
  PDP_TITLE = "ew-pdp-title",
  PDP_SUBTITLE = "ew-pdp-subtitle",
  PDP_LEARN_MORE_CONTAINER = "ew-pdp-learn-more-container",
  PDP_ADD_BUTTON = "ew-pdp-add-button",
  LEARN_MORE_LINK = "ew-learn-more-link",
  SEE_DETAILS_LINK = "ew-see-details-link",
  ADD_BUTTON_CONTAINER = "ew-add-button-container",
  OFFERING_TILES_CONTAINER = "ew-offering-tiles-container",
  OFFERING_TILE_CONTAINER = "ew-offering-tile-container",
  OFFERING_TILE_TITLE = "ew-offering-tile-title",
  OFFERING_TILE_SUBTITLE = "ew-offering-tile-subtitle",
  OFFERING_TILE_PRICE = "ew-offering-tile-price",
  MODAL_CONTAINER = "ew-modal-container",
  MODAL_CONTENT_1 = "ew-modal-content-1",
  MODAL_CONTENT_2 = "ew-modal-content-2",
  MODAL_CONTENT_2_LIST = "ew-modal-content-2-list",
  MODAL_CONTENT_2_LIST_ITEM = "ew-modal-content-2-list-item",
  MODAL_CONTENT_3 = "ew-modal-content-3",
  MODAL_BUTTON_CONTAINER = "ew-modal-button-container",
  MODAL_BUTTON_DECLINE = "ew-modal-button-decline",
  MODAL_BUTTON_ACCEPT = "ew-modal-button-accept",
}

export const getCheapestOffering = (
  offerings: Offering[],
): Offering | undefined => {
  return offerings
    .filter((o) => o.id !== NO_COVERAGE_TIME_ID)
    .reduce((cheapest, current) => {
      if (!cheapest || !current) {
        return current;
      }
      return current.price.amount < cheapest.price.amount ? current : cheapest;
    }, offerings[0]);
};

export const generateNoCoverageOffering = (
  collectionHandle: string,
  currency?: Currency,
  title?: string,
): Offering => {
  return {
    title: title ?? DEFAULT_TEXT.pdpCoverageDisabledLabel,
    id: NO_COVERAGE_TIME_ID,
    collectionHandle: collectionHandle,
    price: { currency: currency ?? Currency.USD, amount: 0 },
  };
};

export const getNoCoverageOffering = (
  offerings?: Record<string, Offering>,
): Offering => {
  return (
    offerings?.[NO_COVERAGE_TIME_ID] ??
    generateNoCoverageOffering(NO_COVERAGE_TIME_ID)
  );
};

export const getDefaultSelectedOffering = (
  offerings: Record<string, Offering>,
  defaultSelection: ExtendedWarrantyDefaultSelection,
): Offering | undefined => {
  switch (defaultSelection) {
    case ExtendedWarrantyDefaultSelection.CHEAPEST:
      return getCheapestOffering(Object.values(offerings));
    default:
      return getNoCoverageOffering(offerings);
  }
};

export const widgetConfigToText = (
  widgetConfig?: ExtendedWarrantyShopMetafieldStyles,
): EWWidgetTextProps => {
  return {
    modalTitle: widgetConfig?.modalTitle ?? DEFAULT_TEXT.modalTitle,
    modalDescription:
      widgetConfig?.modalDescription ?? DEFAULT_TEXT.modalDescription,
    modalCoverageHeader:
      widgetConfig?.modalCoverageHeader ?? DEFAULT_TEXT.modalCoverageHeader,
    modalPlanHeader:
      widgetConfig?.modalPlanHeader ?? DEFAULT_TEXT.modalPlanHeader,
    modalBulletPoints:
      widgetConfig?.modalBulletPoints &&
      widgetConfig?.modalBulletPoints.length > 0
        ? widgetConfig?.modalBulletPoints
        : DEFAULT_TEXT.modalBulletPoints,
    pdpTitle: widgetConfig?.pdpTitle ?? DEFAULT_TEXT.pdpTitle,
    pdpLabel: widgetConfig?.pdpLabel ?? DEFAULT_TEXT.pdpLabel,
    pdpCoverageDisabledLabel:
      widgetConfig?.pdpCoverageDisabledLabel ??
      DEFAULT_TEXT.pdpCoverageDisabledLabel,
    pdpDescription: widgetConfig?.pdpDescription ?? DEFAULT_TEXT.pdpDescription,
    pdpAddToCartDescription:
      widgetConfig?.pdpAddToCartDescription ??
      DEFAULT_TEXT.pdpAddToCartDescription,
    pdpAddedToCartTitle:
      widgetConfig?.pdpAddedToCartTitle ?? DEFAULT_TEXT.pdpAddedToCartTitle,
    pdpAddedToCartDescription:
      widgetConfig?.pdpAddedToCartDescription ??
      DEFAULT_TEXT.pdpAddedToCartDescription,
    pdpCompactBlurbTitle:
      widgetConfig?.pdpCompactBlurbTitle ?? DEFAULT_TEXT.pdpCompactBlurbTitle,
    pdpRadioOptionTitle:
      widgetConfig?.pdpRadioOptionTitle ?? DEFAULT_TEXT.pdpRadioOptionTitle,
    pdpNoCoveragePrice:
      widgetConfig?.pdpNoCoveragePrice ?? DEFAULT_TEXT.pdpNoCoveragePrice,
    cartToggleModalNotAddedDescription:
      widgetConfig?.cartToggleModalNotAddedDescription ??
      DEFAULT_TEXT.cartToggleModalNotAddedDescription,
    cartToggleModalAddedDescription:
      widgetConfig?.cartToggleModalAddedDescription ??
      DEFAULT_TEXT.cartToggleModalAddedDescription,
    cartToggleAddedDescription:
      widgetConfig?.cartToggleAddedDescription ??
      DEFAULT_TEXT.cartToggleAddedDescription,
    cartToggleNotAddedDescription:
      widgetConfig?.cartToggleNotAddedDescription ??
      DEFAULT_TEXT.cartToggleNotAddedDescription,
  };
};

export const composeSeeDetailsUrl = (storeUrl: string): string => {
  return `https://${storeUrl}/apps/redo/warranty-policy`;
};

export type EWWidgetTextProps = {
  modalTitle: string;
  modalDescription: string;
  modalCoverageHeader: string;
  modalPlanHeader: string;
  modalBulletPoints: string[];
  pdpTitle: string;
  pdpLabel: string;
  pdpCoverageDisabledLabel: string;
  pdpDescription: string;
  pdpAddToCartDescription: string;
  pdpAddedToCartTitle: string;
  pdpAddedToCartDescription: string;
  pdpCompactBlurbTitle: string;
  pdpRadioOptionTitle: string;
  pdpNoCoveragePrice: string;
  cartToggleModalNotAddedDescription: string;
  cartToggleModalAddedDescription: string;
  cartToggleAddedDescription: string;
  cartToggleNotAddedDescription: string;
};

export const DEFAULT_TEXT: EWWidgetTextProps = {
  modalTitle: "Enhance your item's protection",
  modalDescription:
    "Our protection plan complements the manufacturer warranty to provide even greater peace of mind",
  modalCoverageHeader: "Extended coverage includes:",
  modalPlanHeader: "Plan options:",
  modalBulletPoints: [
    "Accidental damage such as breaks and drops",
    "100% coverage for the duration of your plan",
    "Hassle-free replacements",
    "Enhanced protection against unexpected failures",
  ],
  pdpTitle: "Protect this item",
  pdpLabel: "Protection",
  pdpCoverageDisabledLabel: "No coverage",
  pdpDescription: "Choose a protection plan in case of accidental damage",
  pdpAddToCartDescription: "Plans starting at %price%",
  pdpAddedToCartTitle: "Item protected",
  pdpAddedToCartDescription: "Added %plan_title% protection plan",
  pdpCompactBlurbTitle: "Add a protection plan",
  pdpRadioOptionTitle: "%plan_title% Protection Plan for %price%",
  pdpNoCoveragePrice: "Free",
  cartToggleModalNotAddedDescription: "Protect this item",
  cartToggleModalAddedDescription: "Added %plan_title% protection plan",
  cartToggleAddedDescription: "Added %plan_title% protection plan",
  cartToggleNotAddedDescription: "%plan_title% protection plan for %price%",
};

export enum WidgetConfigType {
  PDP_OPTIONS = "pdp-options",
  CART_TOGGLE = "cart-toggle",
  MODAL = "modal",
}

const pdpOptionsWidgetConfigSchema = z.object({
  type: z.literal(WidgetConfigType.PDP_OPTIONS),
});

const cartToggleWidgetConfigSchema = z.object({
  type: z.literal(WidgetConfigType.CART_TOGGLE),
});

const modalWidgetConfigSchema = z.object({
  type: z.literal(WidgetConfigType.MODAL),
});

export const widgetConfigSchema = z.discriminatedUnion("type", [
  pdpOptionsWidgetConfigSchema,
  cartToggleWidgetConfigSchema,
  modalWidgetConfigSchema,
]);

export type WidgetConfig = z.infer<typeof widgetConfigSchema>;
