import { z } from "zod";

export const SuggestedVariantOptionsSchema = z.object({
  shouldSuggest: z
    .boolean()
    .describe(
      "Whether the customer should be suggested a variant option at all. If false, the other fields in the schema can be empty.",
    ),
  variantSuggestion: z.string().describe(
    `A single question that should be phrased as 'Would you like to exchange this for a different <variant-title>?' Where <variant-title> is the title of the variant that the most likely reason for the return. For example: 'size' or 'color'.
    If the resulting sentence is doesn't make sense, try to rephrase it to make it more natural.
    `,
  ),
  variantSuggestedResolution: z.string().describe(
    `A short apology that should be similarly phrased to 'We're sorry to hear this product <reason-for-return>.' Where <reason-for-return> is the most likely reason for the return. For example: 'didn't fit well' or 'wasn't the color you wanted'.
    Followed by a single statement that should be phrased as 'We can exchange it for a <variant-option>.' Where <variant-option> is the type of option for the variant that the customer should consider exchanging for. For example: 'bigger size' or 'different color'.
    If the resulting sentences don't make sense, try to rephrase them to make it more natural, but keep it concise.`,
  ),
  suggestedOptions: z
    .array(z.object({ name: z.string(), values: z.array(z.string()) }))
    .describe(
      "An array of available variant options that the customer would likely want to exchange for. For example: [{ name: 'size', values: ['Large', 'Extra Large'] }, { name: 'color', values: ['Red', 'Blue'] }]",
    ),
});

export type SuggestedVariantOptions = z.infer<
  typeof SuggestedVariantOptionsSchema
>;
